export default [
  // {
  //   title: "breadcrumb.administrator",
  //   route: "list-administrator",
  //   icon: "",
  //   resource: "administrator",
  //   action: "read",
  // },

  {
    title: "breadcrumb.administrator",
    icon: "UserIcon",
    children: [
      {
        title: "breadcrumb.administrator",
        route: "list-administrator",
        icon: "",
        resource: "administrator",
        action: "read",
      },
      {
        title: 'breadcrumb.rolePermission',
        route: 'list-role-permission',
        icon: '',
        resource: 'role-permission',
        action: 'read'
      }
    ],
  },
];
