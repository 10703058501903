export default [
  {
    title: 'breadcrumb.promotion',
    header: 'breadcrumb.promotion',
    icon: '',
    children: [
      {
        title: 'breadcrumb.reward',
        route: 'list-reward',
        icon: '',
        resource: 'reward',
        action: 'read',
      },
      {
        title: 'breadcrumb.claimRewardHistory',
        route: 'list-claim-reward-history',
        icon: '',
        resource: 'claim-reward',
        action: 'read',
      },
      {
        title: 'breadcrumb.pointRule',
        route: 'point-rule',
        icon: '',
        resource: 'point-rule',
        action: 'read',
      },
    ],
  },
]
